import React, { useState } from 'react';
import ReactMarkdown from 'react-markdown';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import rehypeRaw from 'rehype-raw';
import BlueTick from '../assets/icons/blue-tick.svg';
import PinkTick from '../assets/icons/pink-tick.svg';
import RadioGroup from './Form/RadioGroup';

import { login } from '@src/utils/mmc-api/auth';
import { createSubscriptionCheckoutSession } from '@src/utils/mmc-api/typedApi';
import Checkbox from './Form';
import { useIsMainSubscribed, useIsQbSubscribed, useIsSubscribedAny } from '@src/hooks/user';

const Subscription = ({ data }) => {
  const user = useSelector((state) => state.user.data);
  const mainSubscribed = useIsMainSubscribed();
  const questionBankSubscribed = useIsQbSubscribed();

  const isSubscribedAny = useIsSubscribedAny();

  const { cardTitle, shortDescription, products, upsell, questionBank, videoUrl } = data;

  const isSubscribed = questionBank ? questionBankSubscribed : mainSubscribed;

  const loggedIn = user?.userId;

  const hasMultipleProducts = products.length > 1;
  const [firstProduct] = products;

  const [selectedProduct, setSelectedProduct] = useState(firstProduct);
  const [price, setPrice] = useState(selectedProduct.price);
  const [upsellId, setUpsellId] = useState(null);

  const handleJoinNowClick = (id, upsellId) => {
    if (isSubscribed) {
      alert('You are already subscribed');
      return;
    }

    if (!loggedIn) {
      localStorage.setItem('redirect', '/subscriptions');
      login();
      return;
    }

    const upsellDiscountCode = upsellId ? upsell?.upsellDiscountCode : null;

    // Redirect to checkout
    createSubscriptionCheckoutSession({
      priceId: id,
      upsellPriceId: upsellId,
      upsellDiscountCode,
      successUrl: window.location.origin,
    }).then((ret) => {
      // console.log(ret);
      if (ret.success && ret.url) {
        window.location.href = ret.url;
      }
    });
  };

  if (!data) return <div></div>;

  const borderColour = data?.borderColour?.hex ?? 'rgba(185,188,193,1)';

  function getUpsellPrice(price, discount) {
    return discount ? price - (price * discount) / 100 : price;
  }

  return (
    <div
      className="px-1.5 mx-1 mb-2 subscription-container"
      style={{ borderColor: borderColour, background: 'white' }}
    >
      <h2>{cardTitle}</h2>

      {hasMultipleProducts ? (
        <>
          <h3 className="mb-0">
            <span className="per-month">Starting from</span>
          </h3>
          <h3>
            <b>£{products[0].price}</b>
            <span className="per-month">/month</span>
          </h3>
        </>
      ) : (
        <>
          <h3 className="mb-0">
            <span className="per-month">
              {shortDescription} (£{selectedProduct.price})
            </span>
          </h3>
          <h3>
            £{(price / selectedProduct.billedEvery).toFixed(2)}
            <span className="per-month">/month</span>
          </h3>
        </>
      )}

      {!isSubscribedAny && upsell && (
        <Checkbox
          id={`${cardTitle}-${upsell.priceId}`}
          label={
            <div className="input-wrapper">
              <p>
                <b>{upsell.title}</b>
              </p>
              <p>
                <span className="old-price">£{upsell.price}</span>
                {upsell.upsellDiscount && `£${getUpsellPrice(upsell.price, upsell.upsellDiscount)}`}
                {upsell.upsellDiscount && (
                  <span className="saving">(save {upsell.upsellDiscount}%)</span>
                )}
              </p>
            </div>
          }
          checked={Boolean(upsellId)}
          onChange={() => {
            // calculate upsell price
            const upsellPrice = getUpsellPrice(upsell.price, upsell.upsellDiscount);

            // add upsell
            if (!upsellId) {
              setUpsellId(upsell.priceId);
              setPrice(price + upsellPrice);
              return;
            }

            // remove upsell
            setPrice(selectedProduct.price);
            setUpsellId(null);
          }}
        />
      )}
      {hasMultipleProducts && (
        <div>
          <RadioGroup
            name="product-selection"
            options={products.map((product) => ({
              id: product.priceId,
              label: `${product.term} (£${product.price})`,
              value: product.priceId,
            }))}
            value={selectedProduct.priceId}
            onChange={(value) => {
              const p = products.find((p) => p.priceId === value);
              setSelectedProduct(p);
              setPrice(p.price);
            }}
          />
        </div>
      )}
      <button
        className="button w-button mx-0 mb-0.5 large-button"
        onClick={() => {
          handleJoinNowClick(selectedProduct.priceId, upsellId);
        }}
      >
        Join now!
      </button>

      {videoUrl && (
        <a
          className="button-outline w-button mx-0 mb-2 large-button"
          href={videoUrl}
          target="_blank"
          rel="noopener noreferrer"
        >
          Watch the video
        </a>
      )}
      <ReactMarkdown className="paragraph" rehypePlugins={[rehypeRaw]} children={data.contents} />
      {data.blueTickContent.map(({ content }) => (
        <div class="srow flex-no-wrap">
          <img src={BlueTick} alt="Blue tick" class="subscription-tick narrow mr-0.25" />
          <p>{content}</p>
        </div>
      ))}
      {data.pinkTickContent.map(({ content }) => (
        <div class="srow flex-no-wrap mt-0.5">
          <img src={PinkTick} alt="Pink tick" class="subscription-tick narrow mr-0.25 scolumn" />
          <p class="scolumn">{content}</p>
        </div>
      ))}
      <ReactMarkdown
        className="paragraph"
        rehypePlugins={[rehypeRaw]}
        children={data.termsAndConditions}
      />
    </div>
  );
};

Subscription.propTypes = {
  data: PropTypes.object,
  loggedIn: PropTypes.bool.isRequired,
};

export { Subscription };
