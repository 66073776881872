import React, { InputHTMLAttributes } from 'react';
import * as styles from './radio-group.module.css';

type RadioOption = {
  id: string;
  label: string;
  value: string;
};

type RadioGroupProps = Omit<InputHTMLAttributes<HTMLInputElement>, 'type'> & {
  name: string;
  options: RadioOption[];
  value?: string;
  onChange?: (value: string) => void;
};

export default function RadioGroup({ name, options, value, onChange, ...props }: RadioGroupProps) {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange?.(e.target.value);
  };

  return (
    <div className={styles.radioGroupContainer}>
      {options.map((option) => (
        <div key={option.id} className={styles.radioItemContainer}>
          <input
            type="radio"
            id={option.id}
            name={name}
            value={option.value}
            checked={value === option.value}
            onChange={handleChange}
            {...props}
          />
          <label htmlFor={option.id} className={`w-form-label ${styles.radioLabel}`}>
            {option.label}
          </label>
        </div>
      ))}
    </div>
  );
}
