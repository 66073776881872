import React, { InputHTMLAttributes } from 'react';
import * as styles from './checkbox.module.css';

type CheckboxProps = InputHTMLAttributes<HTMLInputElement> & {
  label: string;
};

export default function Checkbox(props: CheckboxProps) {
  return (
    <div className={styles.checkboxContainer}>
      <input type="checkbox" {...props} />
      <label htmlFor={props.id} className={`w-form-label ${styles.checkboxLabel}`}>
        {props.label && <span>{props.label}</span>}
      </label>
    </div>
  );
}
